import { instance } from '@/utils/http.js'

// 获取地区列表
export const getPaperDis = function (params) {
  return instance({
    url: '/api/v1/public/paper_districts',
    method: 'get',
    params
  })
}
