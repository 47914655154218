<template>
  <div class="whopaper_warp">
    <div class="left">
      <LeftController ref="leftRef"
                      @initList="initList" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               text="整卷测试"
               :showBack="false" />
      <div class="list">
        <div class="item"
             v-for="(item,index) in tableData"
             :style="{ backgroundColor: getRandomColors(index) }"
             :key="index">
          <div class="module_name"
               ref="targetElement">
            <div class="subject"
                 :style="{ backgroundColor: getsubjectColors(index) }"
                 ref="subject">
              {{subject_name}}
            </div>
            <span>
              {{item.paper_district_name}}
            </span>
          </div>
          <div class="btn"
               @click="toPaperList(item)">
            开始测试
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getPaperDis } from '@/api/paper_dis.js'
export default {
  data () {
    return {
      tableData: [],
      subject_name: '',
      id: '',
      usedColors: [],
      copyColor: []
    }
  },
  components: {
    LeftController, TopCard
  },

  methods: {

    getsubjectColors (index) {
      var colors = [
        "#40B1F5",
        "#FF6E6E",
        "#0BBFB0",
        "#5797ED",
        "#FFC36E",
        "#01C8E9", "#F8B7A8", "#B89EF1"
      ];
      const colorIndex = index % colors.length;
      return colors[colorIndex];
    },
    getRandomColors (index) {
      var colors = ["#7ED8FE", "#FF9AA4", "#1EDBCA", "#9FBEFA", "#FFD99A", "#6EE7F0", "#FFD7CE", "#DACBF9"];
      const colorIndex = index % colors.length;
      return colors[colorIndex];
    },
    async initList (id) {
      console.log('id', id)
      this.id = id
      this.subject_name = this.$refs.leftRef.subjectList.find(item => item.subject_id == id).subject_name
      let params = {
        subject_id: id,
        byWhich: 1
      }
      const { data } = await getPaperDis(params)
      this.tableData = data.list

    },
    toPaperList (row) {
      let form = {
        subject_id: this.id,
        paper_district_id: row.paper_district_id,
      }
      this.$router.push('/wholepaper_list?form=' + JSON.stringify(form))
    }
  }
}
</script>

<style lang="scss" scoped>
.whopaper_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      padding-bottom: 5rem;
      margin-top: -35rem;
      display: flex;
      flex-wrap: wrap;
      -ms-overflow-style: none;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .item {
        width: 260rem;
        height: 190rem;
        margin-left: 40rem;
        flex-shrink: 0;
        border-radius: 20rem;
        box-shadow: 0rem 2rem 8rem 0rem rgba(0, 0, 0, 0.09);
        overflow: hidden;
        margin-top: 50rem;
        .module_name {
          // background: url("../../assets/choiceness/whole_paper_card.png");
          background-size: 100%;
          // background-color: --parent-rgb;
          background-repeat: no-repeat;
          position: relative;
          display: flex;
          justify-content: center;
          height: 130rem;
          .subject {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 20rem 0rem 20rem 0rem;
            color: #ffffff;
            font-weight: 400;
            font-size: 20rem;
            border-radius: 0rem 0rem 16rem 0rem;
            // width: 68rem;
            height: 46rem;
            line-height: 46rem;
            padding: 0rem 20rem;
          }
          span {
            font-size: 24rem;
            font-weight: 500;
            color: #ffffff;
            display: inline-block;
            margin-top: 67rem;
          }
        }
        .btn {
          height: 64rem;
          background: #ffffff;
          font-weight: 400;
          color: #000000;
          font-size: 20rem;
          display: flex;
          justify-content: center;

          border-radius: 0px 0px 0px 0px;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>